<template>
  <ion-fab slot="fixed" vertical="bottom" horizontal="end">
    <ion-fab-button class="btn-primary-gradient">
      <i class="sgri-message fs-2x"/>
    </ion-fab-button>
    <ion-fab-list side="top">
      <ion-fab-button v-if="company != 'mes'" class="btn-primary-gradient" @click="modalContactFormOpen = true" target="_blank">
        <div class="inner">
          <i class="sgri-self-reading fs-2x text-white"/>
          <span class="text-white fw-bold fs-6">{{ $t('buttons.write_us') }}</span>
        </div>
      </ion-fab-button>
      <ion-fab-button class="btn-primary-gradient" :href="$t('company.help_support_link_' + company)" target="_blank">
        <div class="inner">
          <i class="sgri-book fs-2x text-white"/>
          <span class="text-white fw-bold fs-6">{{ $t('buttons.faq') }}</span>
        </div>
      </ion-fab-button>
    </ion-fab-list>
  </ion-fab>
  <contact-form @close="closeContactForm" @sended="sendedContactForm" v-if="modalContactFormOpen"></contact-form>
</template>

<script>
import {computed, defineComponent, ref} from 'vue';
import ContactForm from "@/components/help/ContactFormComponent.vue";
import {
  IonFab,
  IonFabButton,
  IonFabList,
} from '@ionic/vue';

import {useI18n} from "vue-i18n";
import {useSessionStore} from "@/stores/session";

export default defineComponent({
  name: 'FloatHelper',
  components: {
    IonFab,
    IonFabButton,
    IonFabList,
    ContactForm
  },
  props: {},
  setup() {
    const {t} = useI18n();
    const sessionStore = useSessionStore();
    const company = computed(() => sessionStore.getCompany);
    const modalContactFormOpen = ref(false);

    return { t, company, modalContactFormOpen };
  },
  methods: {
    closeContactForm() {
      this.modalContactFormOpen = false;
    },
    sendedContactForm(){
      this.modalContactFormOpen = false;
    }
  }
});
</script>

<style scoped lang="scss">
ion-fab-list {
  right: 0;

  ion-fab-button {
    width: 220px;
    height: 48px;

    &::part(native) {
      border-radius: 40px;
    }

    .inner {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      margin-right: auto;
      padding: 8px 16px;
    }
  }
}
</style>
