<template>
  <ion-menu side="end" content-id="main" :swipe-gesture="false">
    <ion-header class="ion-no-border">
      <ion-toolbar class="px-9 mb-20">
        <ion-button slot="end" color="dark" class="button-close m-0" @click="closeMenu">
          <ion-icon slot="icon-only" name="close"/>
        </ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list class="pl-9 pr-9 mb-16 ion-hide-lg-up">
        <template v-if="company == 'sgr'">
          <ion-item lines="none">
            <a href="https://www.sgrlucegas.com/luce-gas/"
               class="fs-6 fw-medium button-menu ion-text-uppercase"
               v-t="{path:'menu_site.sgr.luce_gas'}"/>
          </ion-item>
          <ion-item lines="none">
            <a href="https://www.sgrlucegas.com/efficienza-energetica/"
               class="fs-6 fw-medium button-menu ion-text-uppercase"
               v-t="{path:'menu_site.sgr.efficienza'}"/>
          </ion-item>
          <ion-item lines="none">
            <a href="https://www.sgrlucegas.com/imprese/"
               class="fs-6 fw-medium button-menu ion-text-uppercase"
               v-t="{path:'menu_site.sgr.imprese'}"/>
          </ion-item>
          <ion-item lines="none">
            <a href="https://www.sgrlucegas.com/magazine/"
               class="fs-6 fw-medium button-menu ion-text-uppercase"
               v-t="{path:'menu_site.sgr.magazine'}"/>
          </ion-item>
          <ion-item lines="none">
            <a href="https://www.sgrlucegas.com/supporto/"
               class="fs-6 fw-medium button-menu ion-text-uppercase"
               v-t="{path:'menu_site.sgr.supporto'}"/>
          </ion-item>
        </template>
        <template v-else-if="company == 'ast'">
          <ion-item lines="none">
            <a href="https://www.asteaenergia.it/offerte-casa/"
               class="fs-6 fw-medium button-menu ion-text-uppercase"
               v-t="{path:'menu_site.ast.casa'}"/>
          </ion-item>
          <ion-item lines="none">
            <a href="https://www.asteaenergia.it/offerte-per-limpresa/"
               class="fs-6 fw-medium button-menu ion-text-uppercase"
               v-t="{path:'menu_site.ast.impresa'}"/>
          </ion-item>
          <ion-item lines="none">
            <a href="https://www.asteaenergia.it/prodotti-e-servizi/"
               class="fs-6 fw-medium button-menu ion-text-uppercase"
               v-t="{path:'menu_site.ast.prodotti_servizi'}"/>
          </ion-item>
          <ion-item lines="none">
            <a href="https://www.asteaenergia.it/magazine/"
               class="fs-6 fw-medium button-menu ion-text-uppercase"
               v-t="{path:'menu_site.ast.magazine'}"/>
          </ion-item>
          <ion-item lines="none">
            <a href="https://www.asteaenergia.it/contatti-e-orari/"
               class="fs-6 fw-medium button-menu ion-text-uppercase"
               v-t="{path:'menu_site.ast.contatti'}"/>
          </ion-item>
        </template>
        <template v-else-if="company == 'sga'">
          <ion-item lines="none">
            <a href="https://www.sgenergia.it/servizi/"
               class="fs-6 fw-medium button-menu ion-text-uppercase"
               v-t="{path:'menu_site.sga.offerte'}"/>
          </ion-item>
          <ion-item lines="none">
            <a href="https://www.sgenergia.it/contatti/"
               class="fs-6 fw-medium button-menu ion-text-uppercase"
               v-t="{path:'menu_site.sga.contatti'}"/>
          </ion-item>
          <ion-item lines="none">
            <a href="https://www.sgenergia.it/convenzioni/"
               class="fs-6 fw-medium button-menu ion-text-uppercase"
               v-t="{path:'menu_site.sga.convenzioni'}"/>
          </ion-item>
        </template>
        <template v-else-if="company == 'mes'">
          <ion-item lines="none">
            <a href="https://metanosudservizi.it/offerte-casa/"
               class="fs-6 fw-medium button-menu ion-text-uppercase"
               v-t="{path:'menu_site.mes.casa'}"/>
          </ion-item>
          <ion-item lines="none">
            <a href="https://metanosudservizi.it/offerte-azienda/"
               class="fs-6 fw-medium button-menu ion-text-uppercase"
               v-t="{path:'menu_site.mes.impresa'}"/>
          </ion-item>
          <ion-item lines="none">
            <a href="https://metanosudservizi.it/info/"
               class="fs-6 fw-medium button-menu ion-text-uppercase"
               v-t="{path:'menu_site.mes.infopoint'}"/>
          </ion-item>
          <ion-item lines="none">
            <a href="https://metanosudservizi.it/contatti/"
               class="fs-6 fw-medium button-menu ion-text-uppercase"
               v-t="{path:'menu_site.mes.contatti'}"/>
          </ion-item>
        </template>
      </ion-list>
      <ion-list class="pl-9 pr-9 mb-16">
        <ion-item lines="none">
          <ion-button size="default" shape="round" class="btn-mysgr" @click="router.push({ name: 'login' })">
            {{ $t('company.short_name_' + company) }}
          </ion-button>
        </ion-item>
      </ion-list>
    </ion-content>
    <ion-footer class="ion-no-border">
      <div class="pl-9 pr-9 pb-16 fw-medium text-dark" style="font-size: 12px;" v-t="{path:'menu.version', args:{version: version}}"/>
    </ion-footer>
  </ion-menu>
</template>

<script>
import {computed, defineComponent} from 'vue';
import {useRouter} from 'vue-router';
import {
  IonHeader,
  IonContent,
  IonFooter,
  IonButton,
  IonToolbar,
  IonItem,
  IonList,
  IonMenu,
  IonIcon,
  menuController
} from '@ionic/vue';
import {addIcons} from 'ionicons';
import {close} from 'ionicons/icons';
import {useSessionStore} from '@/stores/session'
import p from '/package.json';

export default defineComponent({
  name: 'HeaderSiteMenuComponent',
  components: {
    IonHeader,
    IonContent,
    IonFooter,
    IonButton,
    IonToolbar,
    IonIcon,
    IonItem,
    IonList,
    IonMenu,
  },
  setup() {
    const version = p.version;
    const router = useRouter();
    const sessionStore = useSessionStore();
    const company = computed(() => sessionStore.getCompany);

    return {router, version, sessionStore, company};
  },
  created() {
    addIcons({
      'close': close,
    });
  },
  methods: {
    closeMenu() {
      menuController.close('end');
    }
  }
})
</script>

<style scoped lang="scss">
ion-list {
  padding: 0;
}

ion-item {
  &::part(native) {
    min-height: auto;
    padding: 0;
  }

  & + ion-item {
    margin-top: 32px;
  }

  span {
    line-height: 26px;
  }
}
.btn-mysgr{
  &::part(native) {
    height: 40px;
  }
}

.button-menu {
  background: transparent;
  padding-left: 0;
  box-shadow: none;
  text-transform: none;
  color: var(--ion-color-dark);
  text-decoration: none;
  &.router-link-active{
    color: var(--ion-color-primary)
  }
}

ion-button.button-close {
  &::part(native) {
    width: 32px !important;
    height: 32px;
    padding: 5px;
  }
}
</style>
