<template>
  <ion-app>
    <header-menu v-if="sessionStore.isLoggedIn"/>
    <header-site-menu v-else/>
    <ion-router-outlet id="main"/>

    <transition name="fade">
      <feedback v-if="feedback && feedback.show"
                :is-full="feedback.isFull"
                :title="feedback.title"
                :description="feedback.description"
                :first-button="feedback.firstButton"
                :second-button="feedback.secondButton"
      />
    </transition>

    <float-helper v-if="sessionStore.isLoggedIn && !isApp"/>
    <Vue3Lottie v-if="isLoading" :animationData="animation" backgroundColor="transparent"/>

    <ion-modal :is-open="forceUpdateApp" :backdrop-dismiss="false" id="forceUpdateModal">
      <div class="ion-padding ion-text-center">
        <h2 class="fs-5 fw-bold text-dark mb-2">{{ $t('feedback.force_update_app.title') }}</h2>
        <p class="fs-7 text-dark mb-2">{{ $t('feedback.force_update_app.description') }}</p>
        <div>
          <ion-button size="default" shape="round" class="btn-mysgr my-6 mx-auto" @click="openStore">
            <span v-t="{path:'buttons.update_now'}"/>
            <ion-spinner v-if="loading" name="crescent" slot="end" class="position-absolute-middle-right"/>
          </ion-button>
        </div>
      </div>
    </ion-modal>
  </ion-app>
</template>


<script>
import {IonApp, IonButton, IonModal, IonRouterOutlet, IonSpinner, useIonRouter, isPlatform} from '@ionic/vue';
import {computed, defineComponent, getCurrentInstance, onMounted, ref} from 'vue';
import {useSessionStore} from '@/stores/session'
import HeaderMenu from '@/components/headers/MenuComponent'
import HeaderSiteMenu from '@/components/headers/SiteMenuComponent'
import Feedback from '@/components/FeedbackComponent'
import FloatHelper from "@/components/help/FloatComponent";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import animationSgr from '@/assets/media/loader_animation_sgr.json';
import animationAst from '@/assets/media/loader_animation_ast.json';
import animationSga from '@/assets/media/loader_animation_sga.json';
import animationMsu from '@/assets/media/loader_animation_mes.json';

import {Vue3Lottie} from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import {useContractsStore} from "@/stores/contracts";
import {useNotificationsStore} from "@/stores/notifications";
import helpers from "@/helpers";
import {Network} from "@capacitor/network";
import {SplashScreen} from "@capacitor/splash-screen";
import {PushNotifications} from "@capacitor/push-notifications";
import {App} from "@capacitor/app";
import {Device} from "@capacitor/device";
import {NativeBiometric} from "@capgo/capacitor-native-biometric";
import {Keyboard} from "@capacitor/keyboard";
import { AppUpdate } from '@capawesome/capacitor-app-update';
import {Directory, Filesystem} from "@capacitor/filesystem";
import {FileOpener} from "@capacitor-community/file-opener";
import p from '/package.json';
import {router} from "@/router";

export default defineComponent({
  name: 'App',
  components: {
    IonSpinner, IonModal, IonButton,
    IonApp,
    IonRouterOutlet,
    HeaderMenu,
    HeaderSiteMenu,
    Feedback,
    FloatHelper,
    Vue3Lottie,
  },
  data() {
    return {
      loading: null,
      forceUpdateApp: false,
      modulesParsed: false
    }
  },

  watch: {
    sessionId: {
      handler() {
        this.sessionStore.modules(false).then((res) => {
          if(res.success)
          {
            this.addRoutesByModules(res.data);
          }
        })
      }
    },
    sessionCompany: {
      handler: function(newValue) {
        if(newValue === 'ast') this.animation = animationAst;
        else if(newValue === 'sga') this.animation = animationSga;
        else if(newValue === 'sgr') this.animation = animationSgr;
        else if(newValue === 'msu') this.animation = animationMsu;
      },
      deep: true
    }
  },

  created() {

    if(this.$isApp){
      const self = this;

      // Controllo versione minima app
      const hours = 3 * 60 * 60 * 1000; // 3 ore
      self.checkMinVersionApp();
      setInterval(function () {
        self.checkMinVersionApp();
      }, hours);
      PushNotifications.addListener('pushNotificationReceived', (notification) => {  self.pushNotificationActionPerformed(notification, true);   }
      );
      PushNotifications.addListener('registration',(token) => { self.$registrationNotification(token); }
      );
      PushNotifications.addListener('registrationError',
          () => { self.$registrationError(); }
      );
      PushNotifications.addListener('pushNotificationActionPerformed', (notification) => { self.pushNotificationActionPerformed(notification,false); });

      Keyboard.addListener('keyboardDidShow', () => {
        var ionTab = document.querySelector('ion-router-outlet .ion-page:not(.ion-page-hidden) .footer-md');
        if(ionTab) ionTab.classList.add('hidden');
      });

      Keyboard.addListener('keyboardDidHide', () => {
        var ionTab = document.querySelector('ion-router-outlet .ion-page:not(.ion-page-hidden) .footer-md');
        if(ionTab) ionTab.classList.remove('hidden');
      });

      App.getInfo().then((res0) => {
        Device.getInfo().then((res1) => {
          NativeBiometric.isAvailable().then((res2) => {
            localStorage.setItem('nb', ( res2.isAvailable ? '1' : null ) );
          }).catch(()=>{
            localStorage.setItem('nb', '0' );
          });
          localStorage.setItem('platform', res1.platform );
          const sessionStore = useSessionStore();
          sessionStore.setClientSpec({ version: res0.version, type: res1.platform, appId: res0.id } );
        });
      });
    }
    else
    {
      const sessionStore = useSessionStore();
      sessionStore.setClientSpec({ version: p.version, type: 'Browser' } );
    }
  },

  setup() {
    const sessionStore = useSessionStore();
    const contractsStore = useContractsStore();
    const notificationsStore = useNotificationsStore();
    const ionRouter = useIonRouter();
    const route = useRoute();
    const t = useI18n();
    const linked = ref(false);
    const isApp = isPlatform('capacitor');
    const animation = ref(animationSgr);

    const sessionId = computed(() => sessionStore.sessionId);
    const sessionCompany = computed(() => sessionStore.company);
    const { proxy } = getCurrentInstance();

    const addRoutesByModules = (modules) => {
      if (Array.isArray(modules)) {
        modules.forEach((el) => {
          if (proxy.$modulesEnabled.includes(el.key)) {
            if (typeof proxy.$routesAvailables[el.key] == 'object') {
              proxy.$routesAvailables[el.key].forEach((val) => {
                if (!router.hasRoute(val.name)) {
                  router.addRoute(val);
                }
              });
            }
          }
        });
      }
    };

    if(sessionId.value)
    {
      sessionStore.modules(false).then((res)=>{
        addRoutesByModules(res.data);
      });
    }


    const loginUserWithLink = (user) => {
      localStorage.setItem('_u', JSON.stringify(user));
      sessionStore.$patch({
        user: user
      });
    };

    const downloadInvoice = (invoice) => {
      sessionStore.setLoading(true);
      contractsStore.getDocument(invoice.key, 'Invoice').then((res) => {
        if (res.success) {
            const contentType = res.data.contentType;
            const b64Data = res.data.data;
            const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
                const byteCharacters = atob(b64Data);
                const byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    const slice = byteCharacters.slice(offset, offset + sliceSize);

                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }

                const blob = new Blob(byteArrays, {type: contentType});
                return blob;
            }

            const blob = b64toBlob(b64Data, contentType);
            const blobUrl = URL.createObjectURL(blob);

            if(isApp)
            {
              var explodeContentType = contentType.split('/');
              const fileName = 'fattura'+invoice.id+'.'+explodeContentType[1];
              Filesystem.writeFile({
                path: fileName,
                data: b64Data,
                directory: Directory.Documents
              }).then((res)=>{
                FileOpener.open({
                  filePath: res.uri,
                  contentType: contentType
                })
              });
            }else if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0)
                window.location.assign(blobUrl);
            else
                window.open(blobUrl);

        } else {
            sessionStore.setLoading(false);
            window.location.href = '/errore';
        }
      }).catch((error) => {
          helpers.manageCatchError(error)
      }).finally(() => {
          sessionStore.setLoading(false);
      });
    };

    if (typeof route.query.sessionId !== "undefined" && route.query.sessionId && typeof route.query.supplyKey !== "undefined" && route.query.supplyKey && typeof route.query.customerKey !== "undefined" && route.query.customerKey && route.query.rcrm == '1') {
      sessionStorage.setItem('disable_tour', 1);
      sessionStorage.setItem('linked', 1);
      const sessionId = route.query.sessionId;
      sessionStore.clearSession();
      const supplyKey = route.query.supplyKey;
      const customerKey = route.query.customerKey;
      sessionStore.setSessionId(sessionId);
      localStorage.setItem('_sId', sessionId);

      contractsStore.getCustomer(customerKey, false, true).then((res) => {
        if (res.success) {
          contractsStore.setCustomer(res.data);
          ionRouter.push({ name: 'supply_graphs_anon', params: {id: supplyKey} });
        }
      });

    }else if (typeof route.query.linkId !== "undefined" && route.query.linkId
        && typeof route.query.hash !== "undefined" && route.query.hash) {

      sessionStore.clearSession();
      const linkId = route.query.linkId;
      const hashCode = route.query.hash;
      const query = route.query;
      linked.value = true;
      let addParams = {};

      sessionStorage.setItem('disable_tour', 1);
      sessionStorage.setItem('linked', 1);
      sessionStore.createSession({linkId, hashCode}).then((res) => {
        if (res.success) {
          let userId = ( res.data?.user?.id ? res.data.user.id : null );
          sessionStore.getLink(linkId, hashCode).then((resLink) => {
              var user = res.data?.user;
              var isGuest = res.data?.roles == 'Guest';
              sessionStore.setLinkUserId(user.id);

              const moduleId = resLink.data.module.id;
              if(resLink.data.additionalParams.length){
                addParams = JSON.parse(resLink.data.additionalParams);
              }

              if(isApp) SplashScreen.hide();

              // ModuleId
              // 1 = download fatture
              // 5 = recupero psw
              // 6 = Risposta delegante
              // 7 = Invio autolettura

              // Sessione che richiede il login
              if([1].includes(moduleId) || !isGuest)
              {
                if(!userId)
                {
                  if(resLink.data.additionalParams.length){
                    addParams = JSON.parse(resLink.data.additionalParams);
                    if(addParams && addParams.userId){
                      userId = addParams.userId;
                    }
                  }
                }

                if(userId)
                {
                  sessionStore.getUser(userId).then((res2) => {
                    if(res2.success)
                    {
                      var user = res2.data;
                      switch (moduleId) {
                        case 1:
                          loginUserWithLink(user);
                          if(( addParams && addParams.contaFatture > 1 ) || !resLink.data.entityKey)
                          {
                            if(addParams.listaFatture && addParams.listaFatture.length > 0)
                            {
                              let idInvoice = addParams.listaFatture.pop();
                              contractsStore.getInvoice(idInvoice, false).then((res) => {
                                if (res.success) {
                                  let invoice = res.data;
                                  localStorage.setItem('_cId', invoice.contract.customerId);
                                  contractsStore.$patch({
                                    customer: invoice.contract.customer,
                                    customerId: invoice.contract.customerId
                                  });
                                  if([1,2,4,5,31].includes(invoice.invoiceTypeCode))
                                    ionRouter.push({ name: 'invoices' });
                                  else
                                    downloadInvoice(invoice);
                                }
                              });
                            }
                          }
                          else
                          {
                            contractsStore.getInvoice(resLink.data.entityKey, true).then((res) => {
                              if (res.success) {
                                let invoice = res.data;
                                localStorage.setItem('_cId', invoice.contract.customerId);
                                contractsStore.$patch({
                                  customer: invoice.contract.customer,
                                  customerId: invoice.contract.customerId
                                });
                                if([1,2,4,5,31].includes(invoice.invoiceTypeCode))
                                  ionRouter.push('/invoice/' + resLink.data.entityKey + '/?useExtKey=1&nobk=1');
                                else
                                  downloadInvoice(invoice);
                              }
                            });
                          }
                          break;
                        case 4:
                          loginUserWithLink(user);
                          if(typeof res.data.link.item.message.id !== 'undefined')
                            ionRouter.push({ name: 'message', params: { id: res.data.link.item.message.id } });
                          else
                            ionRouter.push('/errore');
                          break;
                        case 5:
                          if (!addParams.firstAccess) {
                            ionRouter.push('/recupero-psw');
                          } else {
                            ionRouter.push('/crea-psw');
                          }
                          break;
                        case 6:
                          //Risposta delegante
                          sessionStore.workflowTaskExecute({workflowId: resLink.data.entityKey, reply: query.selectedValue, taskName:addParams.surveyKind }).then(() => {
                            sessionStore.setFeedbackData({
                              isFull: true,
                              title:t.t('feedback.response_delegate.title_' + query.selectedValue.toLowerCase()),
                              description:t.t('feedback.response_delegate.description_' + query.selectedValue.toLowerCase()),
                              firstButton:{label:t.t('feedback.buttons.back_to'), link: '/dashboard'},
                            })
                          }).catch(() => {
                            ionRouter.push('/errore');
                          });
                          break;
                        case 7:
                          loginUserWithLink(user);
                          if(typeof res.data.link.item.supply.id !== 'undefined')
                            ionRouter.push({ name: 'supply_self_reading', params: { id: res.data.link.item.supply.id } });
                          else
                            ionRouter.push('/errore');
                          break;
                        case 8:
                          loginUserWithLink(user);
                          if(typeof res.data.link.item.proposal.id !== 'undefined')
                            ionRouter.push({ name: 'proposal', params: { id: res.data.link.item.proposal.id } });
                          else
                            ionRouter.push('/errore');
                          break;
                        case 9:
                          ionRouter.push('/pun');
                          break;

                        default:
                          ionRouter.push('/errore');
                      }
                    }
                    else
                    {
                      switch (moduleId) {
                        case 5:
                          if (!addParams.firstAccess) {
                            ionRouter.push('/recupero-psw');
                          } else {
                            ionRouter.push('/crea-psw');
                          }
                          break;
                        default:
                          ionRouter.push('/errore');
                      }
                    }
                  }).catch(() => {
                    ionRouter.push('/errore');
                  });
                }
                else
                {
                  ionRouter.push('/errore');
                }
              }
              else
              {
                switch (moduleId) {
                  case 4:
                    if(typeof res.data.link.item.message.id !== 'undefined')
                      ionRouter.push({ name: 'message', params: { id: res.data.link.item.message.id } });
                    else
                      ionRouter.push('/errore');
                    break;

                  case 5:
                    if (!addParams.firstAccess) {
                      ionRouter.push('/recupero-psw');
                    } else {
                      ionRouter.push('/crea-psw');
                    }
                    break;
                  case 6:
                    //Risposta delegante
                    sessionStore.workflowTaskExecute({workflowId: resLink.data.entityKey, reply: query.selectedValue, taskName:addParams.surveyKind }).then(() => {
                      sessionStore.setFeedbackData({
                        isFull: true,
                        title:t.t('feedback.response_delegate.title_' + query.selectedValue.toLowerCase()),
                        description:t.t('feedback.response_delegate.description_' + query.selectedValue.toLowerCase()),
                        firstButton:{label:t.t('feedback.buttons.back_to'), link: '/dashboard'},
                      })
                    }).catch(() => {
                      ionRouter.push('/errore');
                    });
                    break;
                  case 7:
                    if(typeof res.data.link.item.supply.id !== 'undefined')
                      ionRouter.push({ name: 'supply_self_reading', params: { id: res.data.link.item.supply.id } });
                    else
                      ionRouter.push('/errore');

                    break;
                  case 8:
                    if(typeof res.data.link.item.proposal.id !== 'undefined')
                      ionRouter.push({ name: 'proposal', params: { id: res.data.link.item.proposal.id } });
                    else
                      ionRouter.push('/errore');

                    break;

                  case 9:
                      ionRouter.push('/pun');
                    break;
                  default:
                    ionRouter.push('/errore');
                }
              }
          }).catch(() => {
            ionRouter.push('/errore');
          });
        }
        else
        {
          ionRouter.push('/errore');
        }
      }).catch(() => {
        sessionStore.setErrorMessage('Link scaduto, inserire nuovamente i dati');
        ionRouter.push('/login');
      });
    }
    else if (typeof route.query.s !== "undefined" && route.query.s) {
      sessionStore.clearSession();
      const s =  window.atob(route.query.s).split('##');
      let sessionId = s[0];
      sessionStore.setSessionId(sessionId);
      localStorage.setItem('_sId', sessionId);
      sessionStore.modules(false).then((res) => {
        if(res.success)
        {
          sessionStore.getSession().then((res) => {
            if (res.success) {
              let user = res.data.user;
              sessionStore.setLinkUserId(user.id);
              contractsStore.$patch({
                contracts: user.contracts.contract
              });
              localStorage.setItem('_u', JSON.stringify(user));
              window.location.href = '/dashboard';
              //ionRouter.push('/dashboard');
            }
          })
        }
        else
        {
          ionRouter.push('/errore');
        }
      });
    }

    onMounted(() => {
      const isLogged = sessionStore.isLoggedIn;

      if(!linked.value)
      {
        // Redirect se siamo nella index
        if (route.name === 'index') {
          if (isLogged) ionRouter.push('/dashboard');
          else ionRouter.push('/login');
        } else {
          if (!sessionStore.sessionId) sessionStore.createSession();
          else sessionStore.getSession().then(() => {
            if (!isLogged && sessionStore.isLoggedIn)
              ionRouter.push('/dashboard');
          })
        }
      }

      Network.addListener('networkStatusChange', status => {
        if(!status.connected){
          ionRouter.push('/errore/offline');
        }
      });

    });

    if(typeof process.env.VUE_APP_COMPANY !== 'undefined')
    {
      if(process.env.VUE_APP_COMPANY.toLowerCase() === 'ast') animation.value = animationAst;
      else if(process.env.VUE_APP_COMPANY.toLowerCase() === 'sga') animation.value = animationSga;
      else if(process.env.VUE_APP_COMPANY.toLowerCase() === 'msu') animation.value = animationMsu;
    }
    else
    {
      if(sessionCompany.value === 'ast') animation.value = animationAst;
      else if(sessionCompany.value === 'sga') animation.value = animationSga;
      else if(sessionCompany.value === 'msu') animation.value = animationMsu;
    }

    return {sessionStore, animation, sessionCompany, notificationsStore, contractsStore, ionRouter, isApp, sessionId, addRoutesByModules };
  },
  computed: {
    isLoading() {
      return this.sessionStore.loading;
    },
    feedback() {
      return this.sessionStore.getFeedbackData;
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('orientationchange', this.handleWindowOrientationChange);
    this.sessionStore.setWindowWidth(window.innerWidth);
    if(typeof screen.orientation !== 'undefined')
      this.sessionStore.setWindowOrientation(screen.orientation.type);
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    if(process.env.VUE_APP_ENVIRONMENT != 'local' && !this.isApp)
    {
      let IubendaConfig = document.createElement("script");
      IubendaConfig.setAttribute("type", "text/javascript");
      if(process.env.VUE_APP_COMPANY == 'SGR')
      {
        IubendaConfig.innerText = "var _iub = _iub || [];" +
            "_iub.csConfiguration = {" +
            "  \"consentOnContinuedBrowsing\": false," +
            "  \"invalidateConsentWithoutLog\": true," +
            "  \"lang\": \"it\"," +
            "  \"perPurposeConsent\": true," +
            "  \"siteId\": 2908864," +
            "  \"whitelabel\": false," +
            "  \"cookiePolicyId\": 24776967," +
            "  \"callback\": { " +
            "      onPreferenceExpressedOrNotNeeded: function(preference) { " +
            "          dataLayer.push({ " +
            "              iubenda_ccpa_opted_out: _iub.cs.api.isCcpaOptedOut() " +
            "          }); " +
            "          if (!preference) { " +
            "              dataLayer.push({ " +
            "                  event: \"iubenda_preference_not_needed\" " +
            "              }); " +
            "          } else { " +
            "              if (preference.consent === true) { " +
            "                  dataLayer.push({ " +
            "                      event: \"iubenda_consent_given\" " +
            "                  }); " +
            "              } else if (preference.consent === false) { " +
            "                  dataLayer.push({ " +
            "                      event: \"iubenda_consent_rejected\" " +
            "                  }); " +
            "              } else if (preference.purposes) { " +
            "                  for (var purposeId in preference.purposes) { " +
            "                      if (preference.purposes[purposeId]) { " +
            "                          dataLayer.push({ " +
            "                              event: \"iubenda_consent_given_purpose_\" + purposeId " +
            "                          }); " +
            "                      } " +
            "                  } " +
            "              } " +
            "          } " +
            "      } " +
            "  }," +
            "  \"banner\": {" +
            "    \"acceptButtonCaptionColor\": \"#FFFFFF\"," +
            "    \"acceptButtonColor\": \"#0073CE\"," +
            "    \"acceptButtonDisplay\": true," +
            "    \"backgroundColor\": \"#FFFFFF\"," +
            "    \"backgroundOverlay\": true," +
            "    \"brandBackgroundColor\": \"#FFFFFF\"," +
            "    \"brandTextColor\": \"#000000\"," +
            "    \"closeButtonRejects\": true," +
            "    \"customizeButtonCaptionColor\": \"#4D4D4D\"," +
            "    \"customizeButtonColor\": \"#DADADA\"," +
            "    \"customizeButtonDisplay\": true," +
            "    \"explicitWithdrawal\": true," +
            "    \"fontSizeBody\": \"13px\"," +
            "    \"listPurposes\": true," +
            "    \"logo\": \"https://www.sgrlucegas.com/app/themes/sage/resources/assets/images/logo.svg\"," +
            "    \"position\": \"bottom\"," +
            "    \"textColor\": \"#000000\"" +
            "  }" +
            "};";
      }else if(process.env.VUE_APP_COMPANY == 'AST'){
          IubendaConfig.innerText = "var _iub = _iub || [];" +
              "_iub.csConfiguration = {" +
              "  \"consentOnContinuedBrowsing\": false," +
              "  \"invalidateConsentWithoutLog\": true," +
              "  \"lang\": \"it\"," +
              "  \"perPurposeConsent\": true," +
              "  \"siteId\": 1358660," +
              "  \"whitelabel\": false," +
              "  \"cookiePolicyId\": 43838430," +
              "  \"callback\": { " +
              "      onPreferenceExpressedOrNotNeeded: function(preference) { " +
              "          dataLayer.push({ " +
              "              iubenda_ccpa_opted_out: _iub.cs.api.isCcpaOptedOut() " +
              "          }); " +
              "          if (!preference) { " +
              "              dataLayer.push({ " +
              "                  event: \"iubenda_preference_not_needed\" " +
              "              }); " +
              "          } else { " +
              "              if (preference.consent === true) { " +
              "                  dataLayer.push({ " +
              "                      event: \"iubenda_consent_given\" " +
              "                  }); " +
              "              } else if (preference.consent === false) { " +
              "                  dataLayer.push({ " +
              "                      event: \"iubenda_consent_rejected\" " +
              "                  }); " +
              "              } else if (preference.purposes) { " +
              "                  for (var purposeId in preference.purposes) { " +
              "                      if (preference.purposes[purposeId]) { " +
              "                          dataLayer.push({ " +
              "                              event: \"iubenda_consent_given_purpose_\" + purposeId " +
              "                          }); " +
              "                      } " +
              "                  } " +
              "              } " +
              "          } " +
              "      } " +
              "  }," +
              "  \"banner\": {" +
              "    \"acceptButtonCaptionColor\": \"#FFFFFF\"," +
              "    \"acceptButtonColor\": \"#0073CE\"," +
              "    \"acceptButtonDisplay\": true," +
              "    \"backgroundColor\": \"#FFFFFF\"," +
              "    \"backgroundOverlay\": true," +
              "    \"brandBackgroundColor\": \"#FFFFFF\"," +
              "    \"brandTextColor\": \"#000000\"," +
              "    \"closeButtonRejects\": true," +
              "    \"customizeButtonCaptionColor\": \"#4D4D4D\"," +
              "    \"customizeButtonColor\": \"#DADADA\"," +
              "    \"customizeButtonDisplay\": true," +
              "    \"explicitWithdrawal\": true," +
              "    \"fontSizeBody\": \"13px\"," +
              "    \"listPurposes\": true," +
              "    \"logo\": \"https://www.asteaenergia.it/wp-content/uploads/thegem-logos/logo_7ebfdb6835f059d6d76d1e159bba1fbe_2x.png\"," +
              "    \"position\": \"bottom\"," +
              "    \"textColor\": \"#000000\"" +
              "  }" +
              "};";
      } else if(process.env.VUE_APP_COMPANY == 'SGA'){
        IubendaConfig.innerText = "var _iub = _iub || [];" +
            "_iub.csConfiguration = {" +
            "  \"consentOnContinuedBrowsing\": false," +
            "  \"invalidateConsentWithoutLog\": true," +
            "  \"lang\": \"it\"," +
            "  \"perPurposeConsent\": true," +
            "  \"siteId\": 1542511," +
            "  \"whitelabel\": false," +
            "  \"cookiePolicyId\": 43141129," +
            "  \"callback\": { " +
            "      onPreferenceExpressedOrNotNeeded: function(preference) { " +
            "          dataLayer.push({ " +
            "              iubenda_ccpa_opted_out: _iub.cs.api.isCcpaOptedOut() " +
            "          }); " +
            "          if (!preference) { " +
            "              dataLayer.push({ " +
            "                  event: \"iubenda_preference_not_needed\" " +
            "              }); " +
            "          } else { " +
            "              if (preference.consent === true) { " +
            "                  dataLayer.push({ " +
            "                      event: \"iubenda_consent_given\" " +
            "                  }); " +
            "              } else if (preference.consent === false) { " +
            "                  dataLayer.push({ " +
            "                      event: \"iubenda_consent_rejected\" " +
            "                  }); " +
            "              } else if (preference.purposes) { " +
            "                  for (var purposeId in preference.purposes) { " +
            "                      if (preference.purposes[purposeId]) { " +
            "                          dataLayer.push({ " +
            "                              event: \"iubenda_consent_given_purpose_\" + purposeId " +
            "                          }); " +
            "                      } " +
            "                  } " +
            "              } " +
            "          } " +
            "      } " +
            "  }," +
            "  \"banner\": {" +
            "    \"acceptButtonCaptionColor\": \"#FFFFFF\"," +
            "    \"acceptButtonColor\": \"#A30000\"," +
            "    \"acceptButtonDisplay\": true," +
            "    \"backgroundColor\": \"#FFFFFF\"," +
            "    \"backgroundOverlay\": true," +
            "    \"brandBackgroundColor\": \"#FFFFFF\"," +
            "    \"brandTextColor\": \"#000000\"," +
            "    \"closeButtonRejects\": true," +
            "    \"customizeButtonCaptionColor\": \"#4D4D4D\"," +
            "    \"customizeButtonColor\": \"#DADADA\"," +
            "    \"customizeButtonDisplay\": true," +
            "    \"explicitWithdrawal\": true," +
            "    \"fontSizeBody\": \"13px\"," +
            "    \"listPurposes\": true," +
            "    \"logo\": \"https://www.sgenergia.it/wp-content/uploads/2020/12/logo_SG_Energia.png\"," +
            "    \"position\": \"bottom\"," +
            "    \"textColor\": \"#000000\"" +
            "  }" +
            "};";
      } else if(process.env.VUE_APP_COMPANY == 'MES'){
        IubendaConfig.innerText = "var _iub = _iub || [];" +
            "_iub.csConfiguration = {" +
            "  \"consentOnContinuedBrowsing\": false," +
            "  \"invalidateConsentWithoutLog\": true," +
            "  \"lang\": \"it\"," +
            "  \"perPurposeConsent\": true," +
            "  \"siteId\": 93911462," +
            "  \"whitelabel\": false," +
            "  \"cookiePolicyId\": 93911462," +
            "  \"callback\": { " +
            "      onPreferenceExpressedOrNotNeeded: function(preference) { " +
            "          dataLayer.push({ " +
            "              iubenda_ccpa_opted_out: _iub.cs.api.isCcpaOptedOut() " +
            "          }); " +
            "          if (!preference) { " +
            "              dataLayer.push({ " +
            "                  event: \"iubenda_preference_not_needed\" " +
            "              }); " +
            "          } else { " +
            "              if (preference.consent === true) { " +
            "                  dataLayer.push({ " +
            "                      event: \"iubenda_consent_given\" " +
            "                  }); " +
            "              } else if (preference.consent === false) { " +
            "                  dataLayer.push({ " +
            "                      event: \"iubenda_consent_rejected\" " +
            "                  }); " +
            "              } else if (preference.purposes) { " +
            "                  for (var purposeId in preference.purposes) { " +
            "                      if (preference.purposes[purposeId]) { " +
            "                          dataLayer.push({ " +
            "                              event: \"iubenda_consent_given_purpose_\" + purposeId " +
            "                          }); " +
            "                      } " +
            "                  } " +
            "              } " +
            "          } " +
            "      } " +
            "  }," +
            "  \"banner\": {" +
            "    \"acceptButtonCaptionColor\": \"#FFFFFF\"," +
            "    \"acceptButtonColor\": \"#0073CE\"," +
            "    \"acceptButtonDisplay\": true," +
            "    \"backgroundColor\": \"#FFFFFF\"," +
            "    \"backgroundOverlay\": true," +
            "    \"brandBackgroundColor\": \"#FFFFFF\"," +
            "    \"brandTextColor\": \"#000000\"," +
            "    \"closeButtonRejects\": true," +
            "    \"customizeButtonCaptionColor\": \"#4D4D4D\"," +
            "    \"customizeButtonColor\": \"#DADADA\"," +
            "    \"customizeButtonDisplay\": true," +
            "    \"explicitWithdrawal\": true," +
            "    \"fontSizeBody\": \"13px\"," +
            "    \"listPurposes\": true," +
            "    \"logo\": \"https://metanosudservizi.it/wp-content/uploads/2023/11/logo.svg\"," +
            "    \"position\": \"bottom\"," +
            "    \"textColor\": \"#000000\"" +
            "  }" +
            "};";
      }


      document.head.appendChild(IubendaConfig);

      let IubendaScript = document.createElement("script");
      IubendaScript.setAttribute("src", "//cdn.iubenda.com/cs/iubenda_cs.js");
      IubendaScript.setAttribute("type", "text/javascript");
      document.head.appendChild(IubendaScript);
    }
  },

  methods: {
    pushNotificationActionPerformed(data,inApp){
      var notification = [];
      var mn_msg_id = null;
      if(typeof data.notification !== 'undefined')
      {
        notification = data.notification.data;
        mn_msg_id = notification.mn_message_id;
      }
      else
      {
        notification = data.data;
        mn_msg_id = notification.mn_message_id;
      }

      const self = this;
      var hashCode = ( typeof notification['link.hashCode'] !== 'undefined' ? notification['link.hashCode'] : null );
      var linkId = ( typeof notification['link.id'] !== 'undefined' ? notification['link.id'] : null );
      var notificationId = ( typeof notification['notification.id'] !== 'undefined' ? notification['notification.id'] : null );
      var customerId = ( typeof notification['customer.id'] !== 'undefined' ? notification['customer.id'] : null );
      var pushId = localStorage.getItem('pushNotificationsFbPushId');
      self.notificationsStore.readingNotificationPush(notificationId, mn_msg_id, pushId);
      sessionStorage.setItem('redirectPush', '1');

      self.sessionStore.setLoading(true);
      if(!self.sessionStore.getCompany)
      {
        self.sessionStore.setLoading(false);
      }
      else if(hashCode && linkId){
        if(self.sessionStore.getSessionId && sessionStorage.getItem('logged'))
        {
          self.sessionStore.getSession().then((res) => {
            if(res.success)
            {
              self.goToLinkPush(linkId, hashCode, customerId, true, inApp, notificationId);
            }
            else
            {
              self.goToLinkPush(linkId, hashCode, customerId, false, inApp, notificationId);
            }
          });
        }
        else
        {
          self.goToLinkPush(linkId, hashCode, customerId, false, inApp, notificationId);
        }
      }
    },
    goToLinkPush(linkId, hashCode, customerId, sessionValid, inApp, notificationId){
      const self = this;
      self.sessionStore.createSession({linkId, hashCode}, !sessionValid, true).then((res) => {
        if (res.success) {
          if(customerId)
          {
            self.contractsStore.getCustomer(customerId, true).then((res) => {
              if (res.success) {
                self.contractsStore.setCustomer(res.data);
                self.goFromPush(linkId, hashCode, sessionValid, inApp, notificationId);
              }else{
                self.sessionStore.setLoading(false);
              }
            }).catch(() => {
              sessionStorage.removeItem('redirectPush');
              self.sessionStore.setLoading(false);
            });
          }
          else
          {
            self.goFromPush(linkId, hashCode, sessionValid, inApp, notificationId);
          }
        }else{
          sessionStorage.removeItem('redirectPush');
          self.sessionStore.setLoading(false);
        }
      }).catch(() => {
        sessionStorage.removeItem('redirectPush');
        self.sessionStore.setLoading(false);
      });
    },
    goFromPush(linkId, hashCode, sessionValid, inApp, notificationId){
      var customerId = null;
      const self = this;
      var redirectName = '';
      var redirectParams = {};
      self.sessionStore.getLink(linkId, hashCode).then((resLink) => {
        if(resLink.success)
        {
          let moduleId = resLink.data.module.id;
          var tabName = 'tab-button-home';
          switch (parseInt(moduleId)) {
            case 1:
              // @toDO da testare
              redirectName = 'invoice';
              break;
            case 4:
              redirectParams = { id : notificationId };
              redirectName = 'message';
              break;
            case 7:
              tabName = 'tab-button-radio';
              redirectName = 'supply_self_reading';
              customerId = resLink.data.item.supply.contract.customerId;
              redirectParams = { id : resLink.data.item.supply.id };
              break;
            case 8:
              redirectParams = { id : resLink.data.item.proposal.id };
              redirectName = 'proposal';
              break;
            case 9:
              redirectName = 'pun';
              break;
          }
          if(redirectName)
          {
            var obj = { isPush: 1 };
            if(!sessionValid)
              obj = { isPush: 1, isGuest: 1 };

            sessionStorage.setItem('redirectPush', redirectName);
            if(inApp)
            {
              if(customerId)
              {
                self.contractsStore.getCustomer(customerId, true).then((res) => {
                  if (res.success) {
                    self.contractsStore.setCustomer(res.data);
                    if( document.getElementById(tabName) ) document.getElementById(tabName).click();
                    self.sessionStore.setLoading(true);
                    setTimeout(()=>{
                      var params = redirectParams;
                      if(params)
                        self.ionRouter.push({ name: redirectName, query: obj,params: params  });
                      else
                        self.ionRouter.push({ name: redirectName, query: obj  });

                      self.sessionStore.setLoading(false);
                      sessionStorage.removeItem('redirectPush');
                    }, 500);
                  }
                }).catch(() => {
                  sessionStorage.removeItem('redirectPush');
                  self.sessionStore.setLoading(false);
                });
              }
              else
              {
                if( document.getElementById(tabName) ) document.getElementById(tabName).click();
                self.sessionStore.setLoading(true);
                setTimeout(()=>{
                  var params = redirectParams;
                  if(params)
                    self.ionRouter.push({ name: redirectName, query: obj,params: params  });
                  else
                    self.ionRouter.push({ name: redirectName, query: obj  });
                  self.sessionStore.setLoading(false);
                  sessionStorage.removeItem('redirectPush');
                }, 500);
              }
            }
            else
            {
              if(customerId)
              {
                self.contractsStore.getCustomer(customerId, true).then((res) => {
                  if (res.success) {
                    self.contractsStore.setCustomer(res.data);
                    if( document.getElementById(tabName) )document.getElementById(tabName).click();
                    self.sessionStore.setLoading(true);
                    var params = redirectParams;
                    if(params)
                      self.ionRouter.push({ name: redirectName, query: obj,params: params  });
                    else
                      self.ionRouter.push({ name: redirectName, query: obj  });

                    self.sessionStore.setLoading(false);
                    sessionStorage.removeItem('redirectPush');
                  }
                }).catch(() => {
                  sessionStorage.removeItem('redirectPush');
                  self.sessionStore.setLoading(false);
                });
              }
              else
              {
                if( document.getElementById(tabName) ) document.getElementById(tabName).click();
                self.sessionStore.setLoading(true);
                var params = redirectParams;
                if(params)
                  self.ionRouter.push({ name: redirectName, query: obj,params: params  });
                else
                  self.ionRouter.push({ name: redirectName, query: obj  });
                self.sessionStore.setLoading(false);
              }
            }
            sessionStorage.removeItem('redirectPush');
          }
          else
          {
            sessionStorage.removeItem('redirectPush');
            self.sessionStore.setLoading(false);
          }
        }
        else
        {
          sessionStorage.removeItem('redirectPush');
          self.sessionStore.setLoading(false);
        }
      }).catch(() => {
        sessionStorage.removeItem('redirectPush');
      }).finally(()=> self.sessionStore.setLoading(false) );
    },
    checkMinVersionApp(){
      const self = this;
      if(this.sessionStore.getSessionId) {
        this.sessionStore.getConfProperties('App_min_version').then((res) => {
          if (res.success && res.data) {
            self.forceUpdateApp = false;
            const v2 = res.data.value.toString();
            App.getInfo().then((res0) => {
              const v_now = res0.version.toString();
              if (v_now && self.compareVersioning(v_now, v2)) {
                self.forceUpdateApp = true;
              }
            });
          }
        })
      }
    },
    compareVersioning(v1, v2) {
      const numeriv1 = v1.split('.').map(Number);
      const numeriv2 = v2.split('.').map(Number);

      for (let i = 0; i < Math.max(numeriv1.length, numeriv2.length); i++) {
        const numero1 = numeriv1[i] || 0;
        const numero2 = numeriv2[i] || 0;

        if (numero1 > numero2) {
          return false;
        } else if (numero2 > numero1) {
          return true;
        }
      }

      return false;
    },
    handleWindowResize() {
      this.sessionStore.setWindowWidth(window.innerWidth);
      document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
    },
    handleWindowOrientationChange() {
      if(typeof screen.orientation !== 'undefined')
        this.sessionStore.setWindowOrientation(screen.orientation.type);
    },
    openStore(){
      AppUpdate.openAppStore({country:'IT'});
    }
  }
});
</script>

<style scoped lang="scss">
.lottie-animation-container{
  position: absolute;
  background: rgba(255,255,255, .5);
}
 ion-modal#forceUpdateModal .wrapper {
   margin-bottom: 10px;
 }
ion-modal#forceUpdateModal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  padding-right: 15px !important;
  padding-left: 15px !important;
  @media screen and (min-width: 768px){
    > div {
      padding-top: 32px;
      padding-bottom: 32px;
      padding-right: 32px !important;
      padding-left: 32px !important;
    }
  }
}
ion-modal.modal-default.show-modal {
  --backdrop-opacity: 0.95 !important;
}
</style>
