<template>
  <div v-if="messages" :class="customClass">
    <div v-for="(message, index) in messages" :key="index"
         class="text-uppercase  fs-8 fw-bold" :class="color">
      {{ message }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorMessagesComponent",
  props:{
    errors: {
      type: [String, Array],
      default: () => []
    },
    customClass: {
      type: String,
      default: 'ion-text-center ion-margin-vertical'
    },
    color: {
      type: String,
      default: 'text-danger'
    }
  },
  computed:{
    messages(){
      if(typeof this.errors === "string") return [this.errors];
      return  this.errors;
    }
  }
}
</script>

<style scoped>

</style>
